import React, { Fragment } from 'react';
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import moment from 'moment-timezone';
import Helmet from 'react-helmet'
import { rhythm } from "../utils/typography"
import { useQueryParam, StringParam } from "use-query-params"
import Layout from "../components/layout"
import gamePlayBtn from "../img/btn-play.png";
const $ = require("jquery")

const propTypes = {
  data: PropTypes.object.isRequired,
}

function itemSort() {
  let ctg = $('input[name="ctg"]:checked').val();
  let rank = $('input[name="rank"]:checked').val();
  let baseSelector = 'ul.l-nft-section-list li';
  let sortSelector = '';

  //invisible rank "used" when selected category for "cart".
  if( ctg == 'cart' ) {
    $('#rank-new').removeClass('disnon');
  } else {
    $('#rank-new').addClass('disnon');
  }

  if( ctg ) sortSelector = sortSelector+'.'+ctg;
  if( rank ) sortSelector = sortSelector+'.'+rank;
  let selected = $(baseSelector + sortSelector);
  $(baseSelector).not(sortSelector).addClass('disnon');
  selected.removeClass('disnon');
  $('#current').text(selected.length);
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

const News = ({ node }) => {
  // console.log('nodenode:', node);
  //const dateDisplay = node.createdAt.split('T')[0].replace(/-/g,'.');
  const dateDisplay = node.displayDate.split('T')[0].replace(/-/g,'.');
  return (
    <div>
      <Link
        style={{ color: `#4E2714`, textDecoration: `none`, fontSize: "1.5rem", fontWeight: 600 }}
        to={`/${getLangDir(node.node_locale)}/news/${node.slug}/`}
      >
        <div
          style={{
            display: `flex`,
            alignItems: `center`,
            fontSize: 14,
            paddingBottom: rhythm(1),
            marginBottom: rhythm(1),
          }}
        >
          <div className="news-list-day">{dateDisplay}</div>
          <div className="news-list-title">{node.title}</div>
        </div>
      </Link>
    </div>
  )
}

const GameLink = () => {
  let gameLink = "https://game.cookinburger.com";
  const [rid, setRid] = useQueryParam('rid', StringParam);

  if (typeof window !== 'undefined') {
    if (rid === undefined && localStorage.getItem("rid") !== null) {
      gameLink = gameLink+"?rid="+localStorage.getItem('rid');
    } else {
      gameLink = rid !== undefined ? gameLink+"?rid="+rid : gameLink;
    }
  }
  return (
    <a onClick={() => handleClick()} className="game_btn_link" href={gameLink} target="_blank">
      <div className="btn-play"><img src={gamePlayBtn} alt="Click to start!" /></div>
    </a>
  )
}

function handleClick() {
  console.log('game link is clicked');
  if (typeof window !== 'undefined') {
    if (localStorage.getItem("rid") !== null) {
      var labelValue = '';
      var rId = '';
      var deferCampaignLog = new $.Deferred().resolve();
      deferCampaignLog.promise()
        .then(function() {
            rId = localStorage.getItem("rid");
            return rId
        })
        .then(function(rId) {
            if (rId === null) {
                rId = 'organic'
            }
            labelValue = rId+' | '+localStorage.getItem("cookinburger_guid_raw")+'-'+rId+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
            dataLayer.push({
                'event' : 'gaEvent',
                'eventCategory': 'Conversion: Campaign',
                'eventAction': 'click',
                'eventLabel': labelValue
            });
            var dataCampaignObj = {
                "Property": 'cookinburger',
                "Guid": localStorage.getItem("cookinburger_guid_raw")+'-'+rId,
                "Rid": rId,
                "Locale": window.pmApp.checkLocale(window.location.href),
                "Lp": window.location.href,
                "Reg": "0",
                "FT" : "0",
                "Conv": "1",
                "Username" : 'n/a',
                "Email": 'n/a',
                "Country": 'n/a',
                "City": 'n/a',
                "LonLat": 'n/a',
                "Ip": 'n/a',
                "Timezone": 'n/a',
                "Ref": localStorage.getItem("ref") !== null ? localStorage.getItem("ref") : 'n/a',
                "Lang": language
            };
            if (localStorage.getItem("game_conv_rid") === null) {
                window.pmApp.sendCampaignLog(dataCampaignObj);
            } else {
                var convDoneArray = localStorage.getItem("game_conv_rid");
                convDoneArray = convDoneArray.split(",");
                if (convDoneArray.indexOf(rId) === -1) {
                    window.pmApp.sendCampaignLog(dataCampaignObj);
                }
            }
        })
        .done(function() {
            return true;
        })
    } else {
        labelValue = 'organic | '+localStorage.getItem("cookinburger_guid_raw")+'-organic'+' | country(n/a) | '+window.pmApp.checkLocale(window.location.href);
        dataLayer.push({
            'event' : 'gaEvent',
            'eventCategory': 'Conversion: Organic',
            'eventAction': 'click',
            'eventLabel': labelValue
        });
    }
  }
}

class IndexPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: true,
          error: false,
          fetchedData: [],
          progress: false,
          dataAvail: false,
      };
    }

  componentDidMount() {
    // API call data fetch example
    // const AuthStr = process.env.GATSBY_LUCKY_TOKEN;
    // fetch("https://api-luckyfarmer.playmining.com/api/v1/service/last_ranking_result", { headers: { 'Authorization': AuthStr } })
    // .then(response => {
    //   return response.json()
    // })
    // .then(json => {
    //   console.log('json:', json);
    //   if (json.common.code === 403006) {
    //     this.setState({
    //       progress: true,
    //       loading: false,
    //     });
    //   } else if (json.common.code === 0) {
    //     this.setState({
    //       fetchedData: json.data,
    //       loading: false,
    //       dataAvail: true,
    //     });
    //   }
    // })

    const total = $('ul.l-nft-section-list li');
    $('#total').text(total.length);
    itemSort();

    $('input[name="ctg"]').on('change', function() {
      itemSort();
    });

    $('input[name="rank"]').on('change', function() {
      itemSort();
    });
  }

  shuffleArticle = (data, topNews) => {
    let articles = [], fixTopIndex = null;
    const numLength = topNews && topNews.length > 0 ? data.length - topNews.length : data.length;
    for (let i=0; i<numLength; i++) {
      if (data[i]['node']['positionTopFixed'] === null || data[i]['node']['positionTopFixed'] === false) {
        articles.push(data[i]);
      } else if (data[i]['node']['positionTopFixed'] == true) {
        fixTopIndex = i;
      }
    }
    // if (fixTopIndex !== null) {
    //   articles.unshift(data[fixTopIndex]);
    // }
    return articles;
  }

  render() {
    const enTopNews = this.props.data.enTop.edges;
    const enNewsEdges = this.shuffleArticle(this.props.data.en.edges, enTopNews);
    const { loading, fetchedData, progress, dataAvail } = this.state;

    return (
      <Layout data={this.props.data} location={this.props.location} key={loading}>
        <Helmet>
          <title>Cookin’ Burger | Play the game and get DEAPcoin! Multitasking Cooking Game</title>
          <meta name="description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />

          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="I'm playing Cookin' Burger!" />
          <meta property="og:description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="I'm playing Cookin' Burger!" />
          <meta name="twitter:description" content="New PlayMining Platform Game! A multi-tasking cooking burger game!" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
        <div className="l-main">

          <div className="l-key-visual">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-logo wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/logo-cb.png" alt="『Cookin’ Burger』" />
              </div>
              <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-title wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                <img className="l-key-visual-title-logo" src="../../assets/img/playmining-logo.png" alt="playmining" />
                <img src="../../assets/img/key-visual-title.png" alt="New PlayMining Platform Game Cookin' Burger" />
                <GameLink />
              </div>
              <div data-sal="slide-up" data-sal-delay="400" data-sal-duration="700" data-sal-easing="ease"
                className="l-key-visual-item wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/key-visual-item.png" alt="" />
              </div>
            </div>

            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
              className="sp-none section-banner-en wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
              <ul>
                {/* <li><a href="https://daa.playmining.com/market?keyword=%E3%80%90SOUL%20Fusers%E3%80%91&isKeyword=1&page=1" target="_blank"><img src="../../assets/img/SOULFuser_presale_en.png" alt="SoulFusers NFT Pre Sale" /></a></li> */}
                <li><a href="https://daa.playmining.com/" target="_blank"><img src="../../assets/img/banner-playmining_en.jpg" alt="PlayMicningNFT NFT購入はこちらから" /></a></li>
                <li><a href="https://www.gate.io/trade/DEP_USDT" target="_blank"><img src="../../assets/img/banner-gate.jpg" alt="gate" /></a></li>
                <li><a href="https://www.okx.com/" target="_blank"><img src="../../assets/img/banner-okx.jpg" alt="OKX" /></a></li>
                <li><a href="https://www.bitmart.com/" target="_blank"><img src="../../assets/img/banner-bitmart.jpg" alt="BitMart" /></a></li>
                <li><a href="https://www.mexc.com/" target="_blank"><img src="../../assets/img/banner-mexc.jpg" alt="MEXC" /></a></li>
              </ul>
            </div>
          </div>

          <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
            className="pc-none section-banner wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
            <ul>
              {/* <li><a href="https://daa.playmining.com/market?keyword=%E3%80%90SOUL%20Fusers%E3%80%91&isKeyword=1&page=1" target="_blank"><img src="../../assets/img/SOULFuser_presale_en.png" alt="SoulFusers NFT Pre Sale" /></a></li> */}
              <li><a href="https://daa.playmining.com/" target="_blank"><img src="../../assets/img/banner-playmining_en.jpg" alt="PlayMiningNFT NFT購入はこちらから" /></a></li>
              <li><a href="https://www.gate.io/trade/DEP_USDT" target="_blank"><img src="../../assets/img/banner-gate.jpg" alt="gate" /></a></li>
              <li><a href="https://www.okx.com/" target="_blank"><img src="../../assets/img/banner-okx.jpg" alt="OKX" /></a></li>
              <li><a href="https://www.bitmart.com/" target="_blank"><img src="../../assets/img/banner-bitmart.jpg" alt="BitMart" /></a></li>
              <li><a href="https://www.mexc.com/" target="_blank"><img src="../../assets/img/banner-mexc.jpg" alt="MEXC" /></a></li>
            </ul>
          </div>

          <div className="section-inner_m">
            <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
              className="l-news-section wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <ul className="news-list">
                  {enTopNews.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                  {enNewsEdges.map(({ node }, i) => (
                    <News node={node} key={node.id} />
                  ))}
                </ul>
                <Link to={`/en/news/`}><div className="news-list-btn">More</div></Link>
            </div>
          </div>

          <div className="l-nft-section">
            <div className="section-inner_m">
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-nft-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/title-01.png" alt="Shop-NFT will be available for pre-sale!" />
              </div>
              <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-nft-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                <img src="../../assets/img/title-sub-01.png" alt="A chance to be one of the first to get ready to open a shop!Become a shop owner and stand out from your rivals!" />
              </div>
              <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-nft-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                    NFTs that enable you to have an advantage in playing<br />
                    "Cookin' Burger" now on sale at PlayMining NFT.
              </p>

              <div className="l-nft-section-sort-rank-btn">
              <Link style={{ color: `#FFF`}} to={`/en/repairrank/`}>
                About Repair Ranks
                <span className="rank-new">NEW</span></Link>
              </div>

              <div className="l-nft-section-sort">
                <div className="l-nft-section-sort-ctg">
                  <div className="l-nft-section-sort-title">Category</div>
                  {/* <div className="l-nft-section-sort-item-sc">
                    <div className="l-nft-section-sort-item">
                      <input className="visually-hidden" type="radio" name="ctg" value="" id="ctg-all"/>
                      <label for="ctg-all">All</label>
                      <input className="visually-hidden" type="radio" name="ctg" value="cart" id="ctg-01" checked/>
                      <label for="ctg-01"><span className="ico-stall"></span>Food Cart</label>
                      <input className="visually-hidden" type="radio" name="ctg" value="truck" id="ctg-02"/>
                      <label for="ctg-02"><span className="ico-truck"></span>Food Truck</label>
                    </div>
                  </div> */}
                  <div className="l-nft-section-sort-item-sc">
                    <div className="l-nft-section-sort-item">
                      <input type="radio" id="ctg-all" name="ctg" value="" />
                      <label for="ctg-all">All</label>
                      <input type="radio" id="ctg-01" name="ctg" value="cart" defaultChecked />
                      <label for="ctg-01">Food Cart</label>
                      <input type="radio" id="ctg-02" name="ctg" value="truck" />
                      <label for="ctg-02">Food Truck</label>
                    </div>
                  </div>
                </div>
                  <div className="l-nft-section-sort-rank">
                    <div className="l-nft-section-sort-title">Rank</div>
                    {/* <div className="l-nft-section-sort-item-sc">
                      <div className="l-nft-section-sort-item">
                        <input className="visually-hidden" type="radio" name="rank" value="" id="rank-all"/>
                        <label for="rank-all">All</label>
                        <input className="visually-hidden" type="radio" name="rank" value="used" id="rank-01" checked />
                        <label for="rank-01">Second hand<span id="rank-new" className="disnon">NEW</span></label>
                        <input className="visually-hidden" type="radio" name="rank" value="economy" id="rank-02"/>
                        <label for="rank-02">Economy</label>
                        <input className="visually-hidden" type="radio" name="rank" value="deluxe" id="rank-03"/>
                        <label for="rank-03">Deluxe</label>
                      </div>
                    </div> */}

                    <div className="l-nft-section-sort-item-sc">
                      <div className="l-nft-section-sort-item">
                        <input type="radio" id="rank-all" name="rank" value="" />
                        <label for="rank-all">All</label>
                        <input type="radio" id="rank-01" name="rank" value="used" />
                        <label for="rank-01">Secondhand</label>
                        <input type="radio" id="rank-02" name="rank" value="economy" defaultChecked />
                        <label for="rank-02">Economy</label>
                        <input type="radio" id="rank-03" name="rank" value="deluxe" />
                        <label for="rank-03">Deluxe</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="l-nft-section-list-txt">
                  <p>* It is possible to switch the display by sorting.</p>
                  <div className="l-nft-section-list-txt-result">All <div id="total"></div> sheets - <span id="current"></span>sheets display</div>
                </div>

                <ul className="l-nft-section-list">
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1479.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1480.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1481.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1482.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1483.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1484.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1485.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1486.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1487.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>

                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1497.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1498.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCIDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1499.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCIDX</h3>
                        <a href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCIDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1500.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1501.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1502.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCODX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCODX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1503.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1504.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="deluxe truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1505.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBEDX</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBEDX&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1555.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1556.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1557.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1558.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1559.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1560.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1561.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1562.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1563.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1591.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1592.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1593.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCIEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCIEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1594.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1595.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1596.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCOEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCOEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1597.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1598.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="economy truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1599.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBEEC</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBEEC&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="soldout used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1609.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1610.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1611.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1612.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1613.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1614.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1615.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Fuji”-JBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Fuji%20JBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1616.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Star”-WBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Star%20WBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used truck" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1617.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Truck “Dragon”-CBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Truck%20Dragon%20CBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>

                    {/* newly added */}
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1573.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1574.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1575.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCISH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCISH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1576.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1577.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1578.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CCOSH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CCOSH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1579.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Fuji”-JBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Fuji%20JBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1580.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Star”-WBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Star%20WBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                    <li className="used cart" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-nft-section-list-sm">
                            <img src="../../nft/1581.png" alt="" />
                        </div>
                        <h3 className="l-nft-section-list-title">Food Cart “Dragon”-CBESH</h3>
                        <a className="daa-link" href="https://daa.playmining.com/market?keyword=Food%20Cart%20Dragon%20CBESH&kind=store&page=1&isKeyword=1" target="_blank"><div className="btn">Buy NFT</div></a>
                    </li>
                 </ul>
            </div>
          </div>

          <div className="l-about-section">
            <div className="section-inner_m">

              <div className="l-about-section-box">
                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/title-02.png" alt="What's Cookin’ Burger?" />
                </div>

                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  "Cookin' Burger" is a multi-tasking action game where you play the role of a burger shop worker, taking orders from different types of customers and serveing them accurately and quickly in order to build your shop's reputation.
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-ui wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/ui.png" alt="" />
                </div>
                <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                    The game simulates the management of a burger shop, which is familiar to people around the world. Despite its simple gameplay, the game requires players to learn the preferences of customers and to think about a more efficient cooking process,  just as if they were running an actual shop.
                </p>

                <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                 className="l-about-section-img wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <img src="../../assets/img/about-section-img.png" alt="" />
                </div>

                <ul className="l-about-section-point">
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-01.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            You can become a shop owner by buying Shop-NFT. There are various grades in shops from inexpensive food stalls with simple cooking processes, to large shops that are expensive and challenging to run but offer great return potentials. You can upgrade to a shop that suits your level of expertise.
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-02.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            Shop-NFT has a parameter called "Reputation". By remembering customers' orders and preferences and serving them quickly, the number of regular customers increases, which in turn will increase repeat rate and leads to higher sales and reputation.<br />
                            On the other hand, by repeating mistakes in serving, poor grilling or failing to serve within the time limit, the shop's reputation will decline.<br />
                            <br />
                            In addition, each time you play the game, a parameter called "Endurance Value" will decrease, and when it reaches zero, you will no longer be able to play the game.
                        </p>
                    </li>
                    <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                     className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                        <div className="l-about-section-point-img">
                            <img src="../../assets/img/icon-03.png" alt="" />
                        </div>
                        <p className="l-about-section-point-txt">
                            NFT owners can hire part-time workers (scholars) to play the game for them. When the part-time workers (Scholars) improve their skills to handle more orders from customers and make the business more successful, they can be recruited on better terms by other shops.
                        </p>
                    </li>
                </ul>
              </div>

              <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
               className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                  <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                  <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-x.png" alt="" /></a></li>
                  <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                  <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                  <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
              </ul>
            </div>
          </div>

        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = propTypes

export default IndexPage

export const pageQuery = graphql`
  query PageEnUsQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    en: allContentfulPost(filter: { node_locale: { eq: "en-US" } }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
    enTop: allContentfulPost(filter: { node_locale: { eq: "en-US" }, positionTopFixed: { eq: true} }, limit: 3, sort: {fields: [displayDate], order: DESC}) {
      edges {
        node {
          id
          contentful_id
          node_locale
          title
          slug
          displayDate
          positionTopFixed
          createdAt
        }
      }
    }
  }
`
